.head-active:after {
    content: "";
    background: #1976d2;
    position: absolute;
    height: 75%;
    transform: rotate(90deg);
    width: 2px;
    bottom: -35px;
}

.MuiTabs-indicator.css-1aquho2-MuiTabs-indicator {
    display: none;
    visibility: hidden;
}

.width120 .MuiTabs-scroller.MuiTabs-fixed {
    overflow: auto !important;
}

.width24px {
    width: 24px !important;
    height: 24px !important;
}

.margin72{
    margin-left: 72px;
}

@media screen and (max-width:768px) {
    .width-50-tab {
        width: 50%;
    }

    .product-dropdown {
        min-width: 96vw !important;
        margin-left: 5px;
    }
    .nftImgSize {
        width: auto;
    }
    .mob-hide{
        visibility: hidden !important;
        position: absolute !important;
    }
    .mob-show{
        visibility: visible !important;
        position: relative !important;
    }
}

@media screen and (max-width:500px) {
    .mob-hight-fit {
        height: fit-content;
    }

    .product-dropdown {
        min-width: 170vw !important;
    }
    .width80per{
        width: 80%;
    }
    .mob-margin15per{
        margin-left: 15% !important;
    }
    .mob-margin15px{
        margin-left: 15px !important;
    }
    .mob-width100per{
        width: 100% !important;
    }
    .mob-pad-l130px{
        padding-left: 130px;
    }
}

@media screen and (max-width:376px) {
    .width120 {
        width: "100vw" !important;
    }
    .product-dropdown {
        min-width: 200vw !important;
    }
    .width150{
        width: 150vw !important;
    }
    .max-width120{
        max-width: 120vw !important;
    }
}

@media screen and (max-width:320px) {
    .product-dropdown {
        min-width: 230vw !important;
    }
}