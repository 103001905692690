.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.address {
  font-size: xx-small;
}

.YellowPending {
  font-size: small;
  color: black;
  background-color: orange;
  border-radius: 5px;
  padding: 6px 10px;
  width: max-content;
}

.greenAccepted {
  font-size: small;
  color: #f3f0f0;
  background-color: #2ac395;
  border-radius: 5px;
  padding: 6px 10px;
  width: max-content;
}

.redRejected {
  color: #f3f0f0;
  font-size: small;
  background-color: #f6465b;
  border-radius: 5px;
  padding: 6px 10px;
  width: max-content;
}

.nftImgSize {
  width: auto;
  height: 300px;
  float: center;
}

.height500 {
  height: 500px;
}

.width-fill{
  width: -webkit-fill-available !important;
}

.red-no {
  float: right;
  font-size: small;
  font-family: Georgia, "Times New Roman", Times, serif;
  margin-right: 10px;
  background-color: red;
  color: #f3f0f0;
  border-radius: 8px;
}

.green-yes {
  font-size: small;
  float: right;
  font-family: Georgia, "Times New Roman", Times, serif;
  margin-right: 10px;
  color: white;
  background-color: green;
  border-radius: 8px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.break-word{
  word-wrap: break-word;
}

.show{
  display: relative;
  visibility: visible;
}

.hide{
  display: absolute;
  visibility: hidden;
}

.width-fitcon{
  width: fit-content;
}

.font12 {
  font-size: 12px;
}

.font14 {
  font-size: 14px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.css-10d9dml-MuiTabs-indicator {
  background-color: #f3f0f0 !important;
}

.css-aym9vq-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #000 !important;
}

.css-1qvr50w-MuiTypography-root {
  font-size: 1rem !important;
}

.react-datetime-picker__clear-button {
  visibility: hidden;
  position: absolute;
}

.react-datetime-picker__wrapper {
  border: none !important;
}

.modal-content {
  position: 'absolute' !important;
  left: '50%' !important;
  top: '50%' !important;
  transform: 'translate(-50%, 50%)' !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}